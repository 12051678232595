import React from 'react'

import { QuestionExpanded } from './QuestionExpanded'

import './styles.scss'

export type Question = {
  question: string
  answer?: string
  link?: string
  linkLabel?: string
}

type FaqCustomProps = {
  questions: Question[]
}

export const FaqCustom = ({ questions }: FaqCustomProps) => {
  return (
    <div className="faq-custom-container">
      {questions.map((question) => (
        <QuestionExpanded key={question.question} {...question} />
      ))}
    </div>
  )
}
