import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import GiftWarranty from 'src/components/GiftWarranty'

const Page = () => {
  return (
    <>
      <GatsbySeo
        title="Vivara, Jornada do Presenteado!"
        description="Gift Warranty"
        language="pt-BR"
        noindex
        nofollow
      />

      <GiftWarranty />
    </>
  )
}

export const query = graphql`
  query GiftWarrantyPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
