import React, { useState } from 'react'
import LeftArrowBlack from 'src/images/svg/icon-arrow-left-black'
import { Link } from 'gatsby'

import type { Question } from './FaqCustom'

export const QuestionExpanded = ({
  question,
  answer = '',
  link,
  linkLabel,
}: Question) => {
  const [showAnswer, setShowAnswer] = useState(false)

  const handleClick = () => {
    setShowAnswer(!showAnswer)
  }

  return (
    <div className="question-expanded-container">
      <div className="question-expanded-question">{question}</div>
      {link && linkLabel && (
        <Link to={link} className="question-expanded-link">
          {linkLabel}
        </Link>
      )}
      <p className={`question-expanded-answer ${showAnswer ? 'expanded' : ''}`}>
        {answer}
      </p>
      {answer && (
        <button
          className={`question-expanded-button ${showAnswer ? 'expanded' : ''}`}
          onClick={handleClick}
        >
          <LeftArrowBlack backgroundColor="#FFF" />
        </button>
      )}
    </div>
  )
}
