import React from 'react'

import { GiftWarrantyBanner } from './GiftWarrantyBanner'
import { GiftWarrantyInfo } from './GiftedJourneyInfo'
import type { Question } from '../FaqCustom/FaqCustom'
import { FaqCustom } from '../FaqCustom/FaqCustom'

import './style.scss'

const GiftWarranty = () => {
  const faqQuestions: Question[] = [
    {
      question: 'Confira toda a nossa política de garantia',
      answer: '',
      link: '/institucional/politica-garantia',
      linkLabel: 'ler regulamento completo',
    },
  ]

  return (
    <div className="gift-warranty-main-container">
      <GiftWarrantyBanner />
      <GiftWarrantyInfo />
      <FaqCustom questions={faqQuestions} />
    </div>
  )
}

export default GiftWarranty
