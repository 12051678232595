import React from 'react'
import {
  DiamondIcon,
  DiamondRingIcon,
  ShoppingBagIcon,
} from 'src/images/gift-warranty'

export const GiftWarrantyInfo = () => {
  const infos = [
    {
      title: 'problemas com o seu presente?',
      subtitle: 'Consertos e trocas gratuitas em até 30 dias.',
      icon: <ShoppingBagIcon />,
    },
    {
      title: 'Cuidados com as joias',
      subtitle: 'Manutenção e polimento das suas joias sempre que precisar.',
      icon: <DiamondIcon />,
    },
    {
      title: 'para alianças e solitários',
      subtitle: 'Faça upgrade por um novo modelo e pague apenas a diferença.',
      icon: <DiamondRingIcon />,
    },
  ]

  return (
    <div className="gift-warranty-info-container">
      <h2 className="gift-warranty-info-title">
        por que ter a garantia compartilhada?
      </h2>
      <span className="gift-warranty-info-divider" />
      <div className="gift-warranty-info-content">
        {infos.map((item) => (
          <div className="gift-warranty-info-item" key={item.title}>
            {item.icon}
            <p className="gift-warranty-info-item-title">{item.title}</p>
            <p className="gift-warranty-info-item-subtitle">{item.subtitle}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
