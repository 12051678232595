import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import imageIntroDesktop from '../../images/gift-warranty/gift-warranty-intro-desktop-banner.png'
import imageIntroMobile from '../../images/gift-warranty/gift-warranty-intro-mobile-banner.png'

export const GiftWarrantyBanner = () => {
  const [targetUrl, setTargetUrl] = useState('/')

  useEffect(() => {
    const currentUrl = window.location.href
    const searchParams = new URLSearchParams(currentUrl?.split('?')[1])
    const warrantyId = searchParams?.get('warrantyId')

    let url = '/garantia-de-presente/validate'

    if (warrantyId) {
      url += `?warrantyId=${warrantyId}`
    }

    setTargetUrl(url)
  }, [])

  return (
    <div className="gift-warranty-banner-background">
      <div className="gift-warranty-banner-container">
        <div className="gift-warranty-banner-image-desktop">
          <img src={imageIntroDesktop} alt="firstStepDesktop" />
        </div>
        <div className="gift-warranty-banner-image-mobile">
          <img src={imageIntroMobile} alt="firstStepMobile" />
        </div>
        <div className="gift-warranty-banner-content">
          <h1 className="gift-warranty-banner-title">
            Parabéns! Você recebeu um presente muito especial
          </h1>
          <p className="gift-warranty-banner-subtitle">
            Tenha a garantia compartilhada e ganhe acesso rápido a todos os
            nossos benefícios
          </p>
          <span className="gift-warranty-banner-divider" />
          <Link to={targetUrl} className="gift-warranty-banner-button">
            começar compartilhamento
          </Link>
        </div>
      </div>
    </div>
  )
}
